export const RELEASE_NOTES = <>
      The latest CoRICAL release on 22/05/24 includes the CoRiCal Long COVID calculator, specifically designed to assess the risks of going to hospital or ICU with COVID-19, and having long COVID six months after COVID-19.
      <br />
      We've also: 
      <ul> 
          <li> 
          Updated the FAQs.
          </li>
          <li> 
          Included some short videos to help you interpret the outputs of the long COVID calculator. 
          </li>
          <li> 
          Included a document of references and assumptions that went into designing the calculator. This can be found in the ‘MORE INFO’ section..
          </li>
      </ul>
  </>;